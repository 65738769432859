import React, { useState, useEffect } from 'react';
import InteractivePortfolio from './components/InteractivePortfolio';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <div className={`App min-h-screen ${darkMode ? 'dark' : ''} bg-background-light dark:bg-background-dark`}>
      <InteractivePortfolio darkMode={darkMode} setDarkMode={setDarkMode} />
    </div>
  );
}

export default App;