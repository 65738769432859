import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Pause, SkipForward, SkipBack, Volume2, VolumeX, ChevronUp, ChevronDown, Music, Info } from 'lucide-react';

const trackCategories = {
    "LoFi/Instrumentals": [
        { title: 'Chilly Gonzales - The Tourist (Piano Cover)', src: '/music/Chilly Gonzales - The Tourist (Piano Cover).mp3', duration: 162 },
        { title: 'Clouds', src: '/music/Clouds.mp3', duration: 154 },
        { title: 'Crashed', src: '/music/Crashed.mp3', duration: 194 },
        { title: 'Forest', src: '/music/Forest.mp3', duration: 73 },
        { title: 'Microchip Memories', src: '/music/Microchip Memories.mp3', duration: 74 },
    ],
    "Hip-Hop": [
        { title: 'Allegro Trap', src: '/music/Allegro Trap.mp3', duration: 193 },
        { title: 'Euphoria', src: '/music/Euphoria.mp3', duration: 164 },
        { title: 'Midnight', src: '/music/Midnight.mp3', duration: 195 },
        { title: 'Pulse', src: '/music/Pulse.mp3', duration: 199 },
        { title: 'Resonance', src: '/music/Resonance.mp3', duration: 235 },
        { title: 'Sueños de Verano', src: '/music/Sueños de Verano.mp3', duration: 137 }
    ],
    "Trap": [
        { title: 'Do Not Disturb', src: '/music/Do Not Disturb.mp3', duration: 166 },
        { title: 'Nebula', src: '/music/Nebula.mp3', duration: 233 },
        { title: 'Running from Time', src: '/music/Running from Time.mp3', duration: 176 },
        { title: 'Velvet', src: '/music/Velvet.mp3', duration: 145 }
    ]
};

const allTracks = Object.values(trackCategories).flat();

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(0.3);
    const [isMuted, setIsMuted] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const audioRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        audioRef.current = new Audio(allTracks[currentTrack].src);
        audioRef.current.volume = volume;

        const setAudioData = () => {
            setDuration(audioRef.current.duration);
            setCurrentTime(audioRef.current.currentTime);
        };

        const setAudioTime = () => setCurrentTime(audioRef.current.currentTime);

        audioRef.current.addEventListener('loadeddata', setAudioData);
        audioRef.current.addEventListener('timeupdate', setAudioTime);
        audioRef.current.addEventListener('ended', nextTrack);

        return () => {
            audioRef.current.removeEventListener('loadeddata', setAudioData);
            audioRef.current.removeEventListener('timeupdate', setAudioTime);
            audioRef.current.removeEventListener('ended', nextTrack);
        };
    }, []);

    useEffect(() => {
        audioRef.current.src = allTracks[currentTrack].src;
        if (isPlaying) audioRef.current.play();
    }, [currentTrack]);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const selectTrack = (index) => {
        setCurrentTrack(index);
        setIsPlaying(true);
        audioRef.current.play();
    };

    const nextTrack = () => {
        setCurrentTrack((prevTrack) => (prevTrack + 1) % allTracks.length);
    };

    const prevTrack = () => {
        setCurrentTrack((prevTrack) => (prevTrack - 1 + allTracks.length) % allTracks.length);
    };

    const handleTimeChange = (e) => {
        const time = parseFloat(e.target.value);
        setCurrentTime(time);
        audioRef.current.currentTime = time;
    };

    const handleVolumeChange = (e) => {
        const vol = parseFloat(e.target.value);
        setVolume(vol);
        audioRef.current.volume = vol;
        setIsMuted(vol === 0);
    };

    const toggleMute = () => {
        if (isMuted) {
            audioRef.current.volume = volume;
            setIsMuted(false);
        } else {
            audioRef.current.volume = 0;
            setIsMuted(true);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <motion.div 
            initial={{ height: '95px' }}
            animate={{ height: isExpanded ? '50vh' : '95px' }}
            transition={{ duration: 0.3 }}
            className="fixed left-0 right-0 bottom-0 bg-gradient-to-r from-primary to-secondary text-white overflow-hidden audio-player"
        >
            <div className="h-full flex flex-col audio-player-inner">
                <button 
                    onClick={toggleExpand}
                    className="w-full flex justify-center h-6 focus:outline-none hover:bg-opacity-10 hover:bg-white transition-colors audio-player-expand-btn"
                >
                    <motion.div
                        animate={{ rotate: isExpanded ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ChevronUp size={24} />
                    </motion.div>
                </button>
                <div className="flex-grow flex flex-col overflow-hidden p-4 audio-player-content">
                    <div className="flex items-center justify-between mb-3 audio-player-controls">
                        <div className="flex items-center space-x-4 w-1/4">
                            <button onClick={prevTrack} className="hover:text-primary-light transition-colors p-2"><SkipBack size={24} /></button>
                            <button onClick={togglePlay} className="bg-button-bg text-white rounded-full p-2 hover:bg-button-hover transition-colors audio-player-play-btn">
                                {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                            </button>
                            <button onClick={nextTrack} className="hover:text-primary-light transition-colors"><SkipForward size={24} /></button>
                        </div>
                        <div className="flex-grow flex mx-4 items-center justify-center w-1/2">
                            <div className="text-center">
                                <div className="text-sm font-medium truncate mb-1">{allTracks[currentTrack].title}</div>
                                <div className="text-xs opacity-75">{formatTime(currentTime)} / {formatTime(duration)}</div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2 audio-player-volume w-1/4 justify-end">
                            <button onClick={toggleMute} className="hover:text-primary-light transition-colors">
                                {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
                            </button>
                            <input
                                type="range"
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume}
                                onChange={handleVolumeChange}
                                className="w-20 appearance-none bg-white bg-opacity-20 h-1 rounded-full outline-none audio-player-volume-bar"
                            />
                        </div>
                    </div>
                    <div className="mb-2 audio-player-progress">
                        <input
                            type="range"
                            min={0}
                            max={duration}
                            value={currentTime}
                            onChange={handleTimeChange}
                            className="w-full appearance-none bg-white bg-opacity-20 h-1 rounded-full outline-none audio-player-progress-bar"
                        />
                        <div className="flex justify-between text-xs mt-1 audio-player-time">
                            <span>{formatTime(currentTime)}</span>
                            <span>{formatTime(duration)}</span>
                        </div>
                    </div>
                    <AnimatePresence>
                        {isExpanded && (
                            <motion.div 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="flex-grow flex overflow-hidden p-4 gap-4 audio-player-expanded"
                            >
                                <div className="w-1/2 flex flex-col bg-white bg-opacity-10 rounded-lg overflow-hidden shadow-lg audio-player-tracklist">
                                    <div className="bg-primary p-3 flex items-center audio-player-tracklist-header">
                                        <Music size={24} className="mr-2" />
                                        <h2 className="text-xl font-bold">Select a Track</h2>
                                    </div>
                                    <div className="overflow-y-auto custom-scrollbar flex-grow audio-player-tracks">
                                        {Object.entries(trackCategories).map(([category, tracks]) => (
                                            <div key={category}>
                                                <h3 className="text-lg font-semibold bg-primary bg-opacity-90 backdrop-blur-sm p-2 sticky top-0 z-10">
                                                    {category}
                                                </h3>
                                                <ul className="space-y-2">
                                                    {tracks.map((track) => (
                                                        <li 
                                                            key={track.title}
                                                            className={`cursor-pointer hover:bg-white hover:bg-opacity-20 p-2 transition-colors ${
                                                                allTracks.indexOf(track) === currentTrack ? 'bg-secondary text-white' : ''
                                                            }`}
                                                            onClick={() => selectTrack(allTracks.indexOf(track))}
                                                        >
                                                            <div className="flex justify-between items-center">
                                                                <span className="truncate mr-2 flex-grow">{track.title}</span>
                                                                <span className="text-sm opacity-75 flex-shrink-0 w-12 text-right">{formatTime(track.duration)}</span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-1/2 flex flex-col bg-white bg-opacity-10 rounded-lg overflow-hidden shadow-lg audio-player-info">
                                <div className="bg-primary p-3 flex items-center audio-player-info-header">
                                <Info size={24} className="mr-2" />
                                        <h2 className="text-xl font-bold">About This Music</h2>
                                    </div>
                                    <div className="overflow-y-auto custom-scrollbar flex-grow p-4 audio-player-info-content">
                                        <p className="mb-4">These tracks are part of my original compositions, showcasing my skills in music production and audio engineering.</p>
                                        <h3 className="text-lg font-semibold mb-2">Why It's Part of My Portfolio</h3>
                                        <p className="mb-2">
                                            This audio player showcases my multidisciplinary skills in digital media. As a developer with a passion for music and interactive experiences, I've included these original compositions to demonstrate:
                                        </p>
                                        <ul className="list-disc pl-5 mb-4 space-y-1">
                                            <li>My ability to create audio content and soundtracks for digital projects and games</li>
                                            <li>Technical skills in implementing interactive audio features in web applications</li>
                                            <li>Understanding of user experience design, integrating audio seamlessly into the portfolio navigation</li>
                                        </ul>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </motion.div>
    );
};

export default AudioPlayer;