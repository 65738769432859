import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardHeader, CardContent, Tabs, TabsContent, TabsList, TabsTrigger } from '../ui';
import { Camera, Code, BarChart, Music, Moon, Sun } from 'lucide-react';
import AudioPlayer from './AudioPlayer';
import SkillsSection from './SkillsSection';
import ProjectShowcase from './ProjectShowcase';
import projectsData from './projectsData';
import AboutMeSection from './AboutMeSection';

const TabButton = ({ active, onClick, children }) => (
  <motion.button
    className={`px-6 py-3 rounded-full text-sm font-medium transition-all duration-300 
      ${active
        ? 'bg-primary text-white shadow-lg scale-105'
        : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
      }`}
    whileHover={{ scale: active ? 1.05 : 1.1 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
  >
    {children}
  </motion.button>
);

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => (
  <motion.button
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    onClick={toggleDarkMode}
    className="fixed top-4 right-4 p-2 rounded-full bg-primary text-white shadow-lg z-10"
  >
    {darkMode ? <Sun size={24} /> : <Moon size={24} />}
  </motion.button>
);

const InteractiveProjectCard = ({ title, description, technologies, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      layout
      onClick={() => setIsOpen(!isOpen)}
      className="mb-6 cursor-pointer"
    >
      <motion.div layout="position">
        <Card className="bg-white dark:bg-gray-800 text-text-light dark:text-text-dark">
          <CardHeader>{title}</CardHeader>
          <CardContent>
            <p className="mb-2">{description}</p>
            <div className="flex flex-wrap gap-2 mb-2">
              {technologies.map((tech, index) => (
                <span key={index} className="bg-secondary-light dark:bg-secondary-dark text-white text-xs font-medium px-2.5 py-0.5 rounded">
                  {tech}
                </span>
              ))}
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p className="mt-4">{details}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </CardContent>
        </Card>
      </motion.div>
    </motion.div>
  );
};

const AnimatedSkillBar = ({ skill, level }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(level);
  }, [level]);

  return (
    <div className="mb-4">
      <div className="flex justify-between mb-1">
        <span className="text-base font-medium text-primary dark:text-primary-light">{skill}</span>
        <span className="text-sm font-medium text-primary dark:text-primary-light">{level}%</span>
      </div>
      <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
        <motion.div
          className="bg-secondary dark:bg-secondary-light h-2.5 rounded-full"
          initial={{ width: 0 }}
          animate={{ width: `${width}%` }}
          transition={{ duration: 0.5 }}
        />
      </div>
    </div>
  );
};

const InteractivePortfolio = () => {
  const [activeTab, setActiveTab] = useState('projects')
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <div className={`flex flex-col min-h-screen text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark`}>
      <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className="flex-grow overflow-y-auto pb-24 md:pb-32">
        <div className="max-w-4xl mx-auto p-4 md:p-6">
          <motion.header
            className="text-center mb-8 md:mb-12"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-primary dark:text-primary-dark">Melih Onat</h1>
            <p className="text-lg md:text-xl text-secondary dark:text-secondary-dark">Digital Media Developer</p>
          </motion.header>

          <nav className="flex justify-center space-x-4 mb-8">
            <TabButton
              active={activeTab === 'projects'}
              onClick={() => setActiveTab('projects')}
            >
              Projects
            </TabButton>
            <TabButton
              active={activeTab === 'skills'}
              onClick={() => setActiveTab('skills')}
            >
              Skills
            </TabButton>
            <TabButton
              active={activeTab === 'about'}
              onClick={() => setActiveTab('about')}
            >
              About Me
            </TabButton>
          </nav>

          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {activeTab === 'projects' && <ProjectShowcase projects={projectsData} />}
              {activeTab === 'skills' && <SkillsSection />}
              {activeTab === 'about' && <AboutMeSection />}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <AudioPlayer />
      </div>
    </div>
  );
};

export default InteractivePortfolio;