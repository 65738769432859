const projectsData = [
    {
      title: "whatsyourtune",
      shortDescription: "A creative web application that integrates cutting-edge computer vision, machine learning, and generative music to explore the dynamic interplay between technology and human emotion.",
      fullDescription: "WhatsYourTune demonstrates the powerful intersection of artificial intelligence and creative expression, showcasing how technology can be used to create deeply personalized and engaging user experiences. By leveraging real-time emotion recognition, the platform delivers a personalized music experience in form of a piano melody tailored to the user’s current emotional state. As emotions shift, the application dynamically adjusts not only the music but also the visual atmosphere. This project was marked with a grade of 1,3.",
      technologies: ["Python", "TensorFlow", "HTML/CSS/JS", "Google Magenta.js"],
      images: ["/images/happy_whatsyourtune.png", "/images/angry_whatsyourtune.png"],
      videoUrl: "https://www.youtube.com/embed/iltL_NA40os",
      liveUrl: "https://whatsyourtune.example.com",
      githubUrl: "https://github.com/melihonat/whatsyourtune"
    },
    {
      title: "NFL Fantasy Draft Assistant",
      shortDescription: "A real-time Fantasy Football Draft application that allows users to conduct live drafts with intelligent player recommendations.",
      fullDescription: "The NFL Draft Assistant is a comprehensive tool designed to enhance the experience of fantasy football enthusiasts. Users can easily set up custom leagues, defining the number of teams and roster positions. The heart of the application is the draft room, where participants can select players in real-time from a dynamically updating list of available players. Key features include customizable league settings with flexible team and roster configurations, real-time draft board updating as players are selected and intelligent player recommendations based on team needs and player value.",
      technologies: ["TypeScript", "React", "Express", "Tailwind CSS", "API Integration"],
      images: ["/images/LeagueSetup.jpg", "/images/DraftRoom.jpg"],
      liveUrl: "https://nfl-fantasy-draft-assistant-098e8a8dc5fc.herokuapp.com/",
      githubUrl: "https://github.com/melihonat/fantasy-draft-helper"
    },
    {
      title: "VisaTIXX",
      shortDescription: "A web application that uses advanced algorithms to predict NFL draft picks with high accuracy.",
      fullDescription: "This project combines data analysis, machine learning, and web development to create a unique interactive experience. Users can see their emotions translated into music in real-time, showcasing the intersection of technology and creativity.",
      technologies: ["Python", "Flask", "Firebase/Firestore", "jQuery", "HTML/CSS/JS"],
      images: ["/path/to/image1.jpg", "/path/to/image2.jpg"]
    },
];

export default projectsData;