import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Code, Palette, Database, Server, Cpu, Music } from 'lucide-react';

const SkillCategory = ({ title, icon: Icon, skills, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`mb-6 rounded-lg shadow-lg ${color} overflow-hidden`}>
      <motion.div 
        className="p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Icon className="mr-2 text-white" size={24} />
            <h3 className="text-lg font-semibold text-white">{title}</h3>
          </div>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <Icon className="text-white" size={24} />
          </motion.div>
        </div>
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.3 }}
            className="px-4 pb-4"
          >
            <div className="flex flex-wrap gap-2">
              {skills.map((skill, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="bg-white bg-opacity-20 text-white px-3 py-1 rounded-full text-sm"
                >
                  {skill}
                </motion.span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SkillsSection = () => {
  const skillCategories = [
    {
      title: "Web Development",
      icon: Code,
      skills: ["React", "Flask", "Node.js", "Express", "HTML5", "CSS3", "JavaScript", "TypeScript"],
      color: "bg-blue-600"
    },
    {
      title: "UI/UX Design",
      icon: Palette,
      skills: ["Figma", "Framer", "Sketch"],
      color: "bg-purple-600"
    },
    {
      title: "Backend & Databases",
      icon: Database,
      skills: ["Heroku", "Google Cloud Platform","PostgreSQL", "MySQL", "Firebase"],
      color: "bg-green-600"
    },
    {
      title: "API Development",
      icon: Server,
      skills: ["RESTful APIs", "API Design", "Postman"],
      color: "bg-red-600"
    },
    {
      title: "Machine Learning",
      icon: Cpu,
      skills: ["TensorFlow", "PyTorch", "Scikit-learn", "Computer Vision", "NLP"],
      color: "bg-yellow-600"
    },
    {
      title: "Audio Production",
      icon: Music,
      skills: ["FL Studio", "Sound Design", "Mixing", "Mastering", "Magenta.js"],
      color: "bg-pink-600"
    }
  ];

  return (
    <div className="p-6 bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h2 className="text-3xl text-primary-light dark:text-secondary-dark font-bold mb-8 text-center">My Skills</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {skillCategories.map((category, index) => (
          <SkillCategory key={index} {...category} />
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;