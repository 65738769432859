import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, ExternalLink, GithubIcon, Maximize2 } from 'lucide-react';
import ImageModal from './ImageModal';

const ProjectCard = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <motion.div 
      layout
      className="bg-white/10 dark:bg-gray-800/30 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden mb-8 transition-all duration-300 hover:shadow-xl hover:bg-white/20 dark:hover:bg-gray-800/40 border border-white/20 dark:border-gray-700/50"
    >
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-3 text-secondary dark:text-secondary-dark">{project.title}</h3>
        <p className="text-primary-dark dark:text-gray-300 mb-4">{project.shortDescription}</p>
        <div className="flex flex-wrap gap-2 mb-4">
          {project.technologies.map((tech, index) => (
            <span key={index} className="bg-secondary-light dark:bg-secondary-dark bg-opacity-30 dark:bg-opacity-30 text-primary dark:text-white px-3 py-1 rounded-full text-sm font-medium">
              {tech}
            </span>
          ))}
        </div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center text-primary-dark dark:text-secondary-dark hover:underline text-sm font-medium transition-colors duration-300"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
          {isExpanded ? <ChevronUp className="ml-1" /> : <ChevronDown className="ml-1" />}
        </button>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="px-6 pb-6"
          >
            <div className="mb-6">
              <h4 className="text-xl font-semibold mb-3 text-secondary dark:text-secondary-dark">Description</h4>
              <p className="text-primary/80 dark:text-gray-300">{project.fullDescription}</p>
            </div>

            {project.images && (
              <div className="mb-6">
                <h4 className="text-xl font-semibold mb-3 text-primary-dark dark:text-secondary-dark">Gallery</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {project.images.map((image, index) => (
                    <div key={index} className="relative group">
                      <img 
                        src={image} 
                        alt={`${project.title} screenshot ${index + 1}`} 
                        className="rounded-lg shadow-md w-full h-auto"
                      />
                      <div 
                        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                        onClick={() => openImageModal(image)}
                      >
                        <Maximize2 className="text-white" size={24} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {project.videoUrl && (
              <div className="mb-6">
                <h4 className="text-xl font-semibold mb-3 text-secondary dark:text-secondary-dark">Demo Video</h4>
                <div className="relative pt-[56.25%] rounded-lg overflow-hidden">
                  <iframe
                    src={project.videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full"
                  ></iframe>
                </div>
              </div>
            )}

            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
              {project.liveUrl && (
                <a 
                  href={project.liveUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center justify-center px-4 py-2 bg-primary-dark dark:bg-primary-light text-white dark:text-gray-800 rounded-full hover:bg-primary dark:hover:bg-primary-light transition-colors duration-300"
                >
                  <ExternalLink size={18} className="mr-2" /> View Live
                </a>
              )}
              {project.githubUrl && (
                <a 
                  href={project.githubUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center justify-center px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
                >
                  <GithubIcon size={18} className="mr-1" /> View on GitHub
                </a>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ImageModal
        isOpen={!!selectedImage}
        onClose={closeImageModal}
        imageSrc={selectedImage}
      />
    </motion.div>
  );
};

const ProjectShowcase = ({ projects }) => {
    if (!Array.isArray(projects) || projects.length === 0) {
      return (
        <div className="p-4 sm:p-6 bg-background-light dark:bg-background-dark">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-primary dark:text-primary-light">Featured Projects</h2>
          <p className="text-sm sm:text-base">No projects to display.</p>
        </div>
      );
    }
  
    return (
      <div className="p-6 bg-background-light dark:bg-background-dark">
        <h2 className="text-3xl font-bold mb-8 text-primary-dark dark:text-secondary">Featured Projects</h2>
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    );
  };
  
  export default ProjectShowcase;