import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, Download, X, Maximize2 } from 'lucide-react';
import ContactSection from './ContactSection';
import ImageModal from './ImageModal';

const AboutMeSection = () => {
  const [activeDocument, setActiveDocument] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const documents = [
    { 
      title: "Curriculum Vitae", 
      icon: FileText, 
      color: "from-blue-500 to-purple-500",
      file: "/documents/Melih Onat - CV.pdf"
    }
  ];

  return (
    <div className="p-6 bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h2 className="text-3xl font-bold mb-12 text-center bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent dark:text-secondary-dark">
        Who am I?
      </h2>
      
      <div className="mb-12 flex flex-col items-center">
        <motion.div 
          className="relative w-64 h-64 mb-8"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="absolute inset-0 bg-gradient-to-br from-primary to-secondary rounded-full opacity-20 blur-lg"></div>
          <motion.div
            className="relative w-full h-full rounded-full overflow-hidden shadow-lg cursor-pointer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsImageModalOpen(true)}
          >
            <img
              src="/images/melih.jpg"
              alt="Melih Onat"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              <Maximize2 className="text-white" size={32} />
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="w-full max-w-3xl bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 relative overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="absolute top-0 right-0 w-40 h-40 bg-gradient-to-br from-primary to-secondary opacity-10 rounded-bl-full"></div>
          <p className="text-lg mb-4 relative z-2">
            First of all, Hi. My name is Melih and I am 22 years old.
          </p>
          <p className="text-lg mb-4 relative z-2">
            As a Digital Media student at the University of Bremen in Germany, I'm passionate about pushing the boundaries of technology and creativity. 
            My journey in digital media is characterized by a unique blend of technical expertise and artistic vision.
          </p>
          <p className="text-lg mb-4 relative z-2">
            I specialize in web development, machine learning, and creative applications of technology. My diverse interests, 
            from sports to music production, build the foundation of my approach to digital media projects.
          </p>
          <p className="text-lg relative z-2">
            I'm excited about the future of digital media and am always seeking new challenges and opportunities to grow in this dynamic field.
          </p>
        </motion.div>
      </div>

      <div className="mb-12 max-w-3xl mx-auto">
        {documents.map((doc, index) => (
          <motion.div
            key={index}
            className={`p-6 rounded-xl shadow-lg cursor-pointer relative overflow-hidden bg-white dark:bg-gray-800`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setActiveDocument(activeDocument === index ? null : index)}
          >
            <div className={`absolute inset-0 bg-gradient-to-br ${doc.color} opacity-10`}></div>
            <div className="relative flex items-center justify-between">
              <div className="flex items-center">
                <doc.icon size={36} className="mr-4 text-primary dark:text-primary-light" />
                <div>
                  <h3 className="text-xl font-semibold mb-1">{doc.title}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">Click to {activeDocument === index ? 'hide' : 'view'} preview</p>
                </div>
              </div>
              <motion.a
                href={doc.file}
                download
                className="flex items-center px-4 py-2 bg-primary text-white rounded-full hover:bg-opacity-80 transition-colors duration-300"
                onClick={(e) => e.stopPropagation()}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Download size={18} className="mr-2" />
                Download
              </motion.a>
            </div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {activeDocument !== null && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="mb-12 bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden max-w-3xl mx-auto"
          >
            <div className="p-4 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
              <h4 className="text-xl font-semibold">{documents[activeDocument].title} Preview</h4>
              <button 
                onClick={() => setActiveDocument(null)}
                className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-4">
              <iframe
                src={`${documents[activeDocument].file}#view=FitH`}
                title={documents[activeDocument].title}
                className="w-full h-[70vh] border-0"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <ContactSection />

      <ImageModal 
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        imageSrc="/images/melih.jpg"
        alt="Melih Onat"
      />
    </div>
  );
};

export default AboutMeSection;