import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Linkedin, Github, ChevronRight, Instagram } from 'lucide-react';

const ContactButton = ({ icon: Icon, text, link }) => (
  <motion.a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-between px-6 py-3 bg-white dark:bg-primary text-primary dark:text-text-dark rounded-full shadow-lg hover:shadow-xl transition-all duration-300 group overflow-hidden"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="flex items-center">
      <Icon size={24} className="mr-2" />
      <span>{text}</span>
    </div>
    <motion.div
      className="flex items-center"
      initial={{ width: 0, opacity: 0 }}
      whileHover={{ width: 'auto', opacity: 1 }}
    >
      <ChevronRight size={24} className="ml-2" />
    </motion.div>
  </motion.a>
);

const ContactSection = () => {
  const contactInfo = [
    { icon: Mail, text: "hello@melihonat.dev", link: "mailto:hello@melihonat.dev" },
    { icon: Linkedin, text: "Melih Onat", link: "https://www.linkedin.com/in/melih-onat/" },
    { icon: Github, text: "melihonat", link: "https://github.com/melihonat" },
    { icon: Instagram, text: "@melih.png", link: "https://www.instagram.com/melih.png/" }
  ];

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-2xl font-semibold mb-6 text-primary dark:text-secondary-dark">Let's connect!</h3>
      <div className="flex flex-wrap justify-center gap-4">
        {contactInfo.map((info, index) => (
          <ContactButton key={index} {...info} />
        ))}
      </div>
    </div>
  );
};

export default ContactSection;